import { environment } from 'src/environments/environment';

export interface JsonData<T> {
    code: number;
    data: T;
    message: string;
}

export class Urls {
    static HostUrl = environment.ServiceURL;
    static getOpenId = '/user/accesstoken';
    static postAfterSaleFeedback = '/api/feedback/afterSale';
}

import { Component } from '@angular/core';
import { PageService } from '../services/page.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from '../services/http.service';
import { Urls } from '../module/response';
import { UserService } from '../services/user.service';
import { MessageService } from '../services/message.service';

@Component({
    selector: 'app-root',
    templateUrl: '../../../resources/default/html/after_sale_feedback.component.html',
})
export class AfterSaleFeedbackComponent {
    title = '8.0键盘售后反馈';
    descShow = false;
    descLength = 0;

    constructor(private page: PageService, private http: HttpService, private user: UserService, private message: MessageService) {
        this.page.setTitle(this.title);
    }

    afterSaleForm: FormGroup = new FormGroup({
        DeviceID: new FormControl(1),
        Type: new FormControl('', Validators.required),
        Desc: new FormControl(''),
        Name: new FormControl('', Validators.required),
        Phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{11}$/)]),
        RecAddress: new FormControl('', Validators.required)
    });

    onType() {
        if (this.afterSaleForm.value.Type === '3') {
            this.descShow = true;
            this.changeDescValidators(true);
        } else {
            this.descShow = false;
            this.changeDescValidators(false);
        }
    }

    onDesc() {
        this.descLength = this.afterSaleForm.value.Desc.length;
    }

    changeDescValidators(option: boolean) {
        const desc = this.afterSaleForm.get('Desc');
        if (option === true) {
            desc.setValidators([
                Validators.maxLength(100),
                Validators.required
            ]);
        } else {
            desc.clearValidators();
            desc.enable();
        }
    }

    submit() {
        if (this.afterSaleForm.valid) {
            const data = this.afterSaleForm.value;
            this.http.post(Urls.postAfterSaleFeedback, data, this.user.getTokenHeader()).then(() => {
                this.message.setMessage('提交成功');
                this.afterSaleForm.reset();
                this.descLength = 0;
            }).catch(() => {
                this.message.setMessage('提交失败');
            });
        }
    }
}

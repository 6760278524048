import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AfterSaleFeedbackComponent } from './component/after_sale_feedback.component';
import { CanActivateRoute } from './module/can_activate_route';


const routes: Routes = [
  { path: '', component: AfterSaleFeedbackComponent, canActivate: [CanActivateRoute] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

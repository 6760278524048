import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    message = '';

    constructor() { }

    /**
     * 设置页面消息
     * @return void
     */

    public setMessage(message: string, time = 1800): void {
        this.message = message;
        setTimeout(() => {
            this.message = '';
        }, time);
    }

}

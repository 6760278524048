<div class="after_sale_feedback">
    <form [formGroup]="afterSaleForm" (submit)="submit()">

        <div class="component">
            <div class="title">MX BOARD 8.0键盘问题</div>
            <div class="radios">
                <input id="1" name="Type" formControlName="Type" type="radio" value="1" (change)="onType()">
                <label for="1">键盘开裂</label>
                <input id="2" name="Type" formControlName="Type" type="radio" value="2" (change)="onType()">
                <label for="2">键轴手感不好</label>
                <input id="3" name="Type" formControlName="Type" type="radio" value="3" (change)="onType()">
                <label for="3">其他</label>
            </div>
        </div>

        <div class="component" *ngIf="descShow">
            <textarea formControlName="Desc" (keyup)="onDesc()" class="question" rows="7"
                placeholder="请详细描述您的问题(100字以内)"></textarea>
            <div class="notice">{{descLength}}/100</div>
        </div>

        <div class="component">
            <div class="title">个人信息
                <span class="desc">（请如实填写信息，以便我们联系您）</span>
            </div>
            <div class="form">
                <div class="input">
                    <div class="key">姓名</div>
                    <input formControlName="Name" placeholder="必填" class="value" type="text">
                </div>
                <div class="input">
                    <div class="key">手机号</div>
                    <input formControlName="Phone" placeholder="必填" class="value" type="tel">
                </div>
                <div class="input">
                    <div class="key">收货地址</div>
                    <input formControlName="RecAddress" placeholder="必填" class="value" type="text">
                </div>
            </div>
        </div>
        <button class="submit bn" [ngClass]="{'submitValid': afterSaleForm.valid}" type="submit">提交</button>
    </form>
</div>
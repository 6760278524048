import { Component, OnInit } from '@angular/core';
import { MessageService } from '../services/message.service';
import { PageService } from '../services/page.service';

@Component({
    selector: 'app-message',
    templateUrl: '../../../resources/message.component.html',

})
export class MessageComponent implements OnInit {

    isMobile;
    constructor(private page: PageService, public messageService: MessageService) { }

    ngOnInit() {
        this.isMobile = this.page.userAgent().isMobile;
    }

}

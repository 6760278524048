import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from './http.service';
import { Urls } from '../module/response';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PageService } from './page.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

export class UserData {
    token = '';
    expiresAt = 0;
    redirectUrl = '';
}

@Injectable({
    providedIn: 'root',
})
export class UserService {

    private app: any = null;
    private data: UserData = new UserData();
    private cookieKey = 'activity';

    private userCenterTheme = 'cherry';
    private userCenterLang = 'zh';

    private loginStatus = new Subject<boolean>();
    loginObservable = this.loginStatus.asObservable();

    constructor(
        private cookie: CookieService,
        private http: HttpService,
        private router: Router,
        private page: PageService,
    ) {
        this.read();
        this.write();
        this.initApp();
    }

    private initApp() {
        this.app = new CherryUser(environment.UserCenterAppID, { theme: this.userCenterTheme, lang: this.userCenterLang });
        this.initEvent();
    }

    public get token() {
        if (this.checklogin()) {
            return this.data.token;
        }
        return '';
    }

    get isLogin() {
        if (this.checklogin()) {
            return true;
        }
        return false;
    }

    public login(route: string = '') {
        this.setRedirectUrl(route);
        if (this.app === null) {
            this.initApp();
        }
        this.app.login();
        this.initStyle();
    }

    public wxLoginCheck() {
        if (this.isLogin === false && /code=(.+?)&state=CherryUserwxCode.+?$/.test(window.location.href)) {
            this.login(this.data.redirectUrl);
        }
    }

    private setLoginStatus(value: boolean) {
        if (value === true && this.checklogin()) {
            this.loginStatus.next(true);
        } else {
            this.loginStatus.next(false);
        }
    }

    private checklogin() {
        const time = Math.round(new Date().getTime() / 1000);
        if (this.data.token && (this.data.token.length === 36 || this.data.token.length === 32) && this.data.expiresAt > time) {
            return true;
        }

        this.data.token = '';
        this.data.expiresAt = 0;
        this.write();

        return false;
    }


    private initEvent() {
        const self = this;
        this.app.onLoginSuccessed((data: any) => {
            self.getOpenId(data.code);
        });

        this.app.onLogout(() => {
            if (self.isLogin === true) {
                self.clear();
                self.setLoginStatus(false);
                self.app = null;
                const currentUrl = window.location.href.split('?');
                window.location.href = currentUrl[0];
            }
        });
    }

    private async getOpenId(code: string) {
        if (code.length > 0) {
            const param = {
                Code: code
            };
            this.http.post<any>(Urls.getOpenId, param).then(res => {
                this.data.token = res.AccessToken.AccessToken;
                this.data.expiresAt = res.AccessToken.ExpiresAt;
                this.write();
                this.setLoginStatus(true);
                this.redirect();
            }, rej => {
                this.loginFaild();
            });
        } else {
            this.loginFaild();
        }
    }

    private setRedirectUrl(route: string) {
        this.data.redirectUrl = route;
        this.write();
    }

    private redirect() {
        if (this.data.redirectUrl) {
            this.router.navigateByUrl(this.data.redirectUrl);
        }
    }

    async logout() {
        if (this.app === null) {
            this.initApp();
        }
        await this.app.weblogout();
    }


    private read() {
        const str = this.cookie.get(this.cookieKey);
        if (!str) {
            this.clear();
        } else {
            this.data = JSON.parse(str);
        }
    }

    private write() {
        const str = JSON.stringify(this.data);
        this.cookie.set(this.cookieKey, str, 730, '/');
    }

    private clear() {
        this.data = new UserData();
        this.write();
        this.read();
    }


    private async loginFaild() {
        this.setLoginStatus(false);
        this.app = null;
    }

    getTokenHeader(): {} {
        return {
            headers: new HttpHeaders({
                Authorization: 'token ' + this.token
            })
        };
    }


    private initStyle() {
        if (!this.page.userAgent().isMobile) {
            // tslint:disable-next-line:quotemark
            // tslint:disable-next-line:max-line-length
            this.app.setStyle({ display: 'block', width: '400px', height: '600px', position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', 'z-index': '2' });
        } else if (this.page.userAgent().isMobile) {
            // tslint:disable-next-line:quotemark
            this.app.setStyle({ display: "block", position: "fixed", "z-index": "2" });
        }

    }
}


import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class PageService {


    constructor(private titleService: Title) {

    }

    /**
     * 初始化页面，监听屏幕调整动作
     * @return void
     */
    public initEvent(): void {
        const self = this;
        self.adjustScreen();
        window.addEventListener('resize', () => {
            self.adjustScreen();
        });
    }

    /**
     * 调整页面整体大小
     * @return void
     */
    private adjustScreen(): void {
        const width = document.documentElement.clientWidth as number;
        let adjustBit = 1;
        if (navigator.userAgent.match('HUAWEI') && width === 720) {
            adjustBit = 2;
        }
        const fontSize = width / (750 / 40 * adjustBit);
        document.documentElement.style.fontSize = fontSize + 'px';
    }

    public userAgent() {
        const ua = window.navigator.userAgent.toLowerCase();
        const res = {
            isWechat: /micromessenger/.test(ua),
            isMobile: /android|iphone|ipad/.test(ua),
            isIphone: /iphone/.test(ua),
            isIFrame: window.frames.length !== parent.frames.length,
            isEdge: /edge/.test(ua)
        };
        return res;
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { JsonData } from '../module/response';
import { throwError } from 'rxjs';
import { MessageService } from './message.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    RootUrl = environment.ServiceURL.replace(/\/$/, '');
    constructor(
        private http: HttpClient,
        private message: MessageService
    ) {
    }

    // TODO
    private handleError(error: HttpErrorResponse) {
        return throwError(error.error);
    }

    get<T>(url: string, options?: {}): Promise<T> {
        return this.getData(url, true, options);
    }
    getData<T>(url: string, showErrMsg: boolean, options?: {}): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.getBase<JsonData<T>>(url, options).then(res => {
                if (res.code === 0) {
                    resolve(res.data);
                } else {
                    reject(res);
                    if (showErrMsg) {
                        this.message.setMessage(res.message);
                    }
                }
            }, res => {
                reject(res);

            });
        });
    }

    post<T>(url: string, data: any, options?: {}): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.postBase<JsonData<T>>(url, data, options).then(res => {
                if (res.code === 0) {
                    resolve(res.data);
                } else {
                    reject(res);
                    this.message.setMessage(res.message);
                }
            }, res => {
                reject(res);
            });
        });
    }

    getBase<T>(url: string, options?: {}) {
        if (url.indexOf('http') < 0) {
            url = this.RootUrl + url;
        }

        return this.http.get<T>(url, options).pipe(
            catchError(this.handleError)
        ).toPromise();
    }

    postBase<T>(url: string, data: any, options?: {}) {
        if (url.indexOf('http') < 0) {
            url = this.RootUrl + url;
        }
        return this.http.post<T>(url, data, options).pipe(
            catchError(this.handleError)
        ).toPromise();
    }
}

import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CanActivateRoute implements CanActivate {

    constructor(private user: UserService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        if (this.user.isLogin) {
            return true;
        }

        this.user.login(state.url);
        return false;
    }


}

import { Component, OnInit } from '@angular/core';
import { PageService } from '../services/page.service';

@Component({
  selector: 'app-root',
  templateUrl: '../../../resources/app.component.html',
  styleUrls: ['../../../resources/app.component.less']
})
export class AppComponent implements OnInit {

  constructor(private page: PageService) { }

  ngOnInit(): void {
    this.page.initEvent();
  }

}
